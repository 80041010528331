<template>
  <div class="page-store-invite-202312">

    <div class="gift-head">
      <div class="to-reward-list" @click="torewardlist">
        您已邀请 {{ inviteNum }}人
        <div class="arrow-icon" />
      </div>
    </div>
    <div class="gift-list">
      <div class="level-model" v-for="(item, key) in rewardMap">
        <div class="level-head">
          <div class="level-num">邀请{{ key }}人</div>
          <div class="finished" v-if="inviteNum >= key">已达成 <span v-if="onlyItem(item)">（三选一）</span></div>
          <div class="nofinished" v-else>未达成</div>
        </div>
        <div class="level-body">
          <div class="reward-model" v-for="citem in item">
            <div class="level-img">
              <img alt="" :src="citem.rewardImg" />
            </div>
            <div class="level-name-box">
              <div class="level-name ellipsis-3">{{ citem.rewardName }}</div>
            </div>

            <div class="level-sub" :class="{ hasget: citem.status == 'RECEIVED_OTHER', op: citem.status != 'CAN_RECEIVE' && citem.status != 'RECEIVED' }" @click="getRewrd(citem)">
              {{ getName(citem.status) }}
              <img alt src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/arrow.png" class="getimg" v-if="citem.status == 'RECEIVED'" />
            </div>
          </div>
        </div>
      </div>

      <p class="f22 fw6" style="color: #A10F11; text-align: center; margin: -8px 0 0;">*奖品以实物为准，数量有限，领完即止</p>
    </div>
    <div class="rule-box">
      <img src="https://img.chaolu.com.cn/ACT/invite-gift/202501/rule-2.png" alt />
    </div>
    <van-popup v-model="showGet" position="center" class="pop-get">
      <div class="pop-get-title">奖品领取确认</div>
      <div class="pop-get-tips">领取当前的奖品后，</div>
      <div class="pop-get-tips">将无法再领取同等级的奖品哦</div>
      <div class="jp">
        <img :src="getObj.rewardImg" />
      </div>
      <div class="pop-get-sub" @click="sureReward">确认领取</div>
      <div class="noget" @click="showGet = false">我再想想</div>
    </van-popup>
    <van-popup v-model="showCollectionRecords" position="bottom" safe-area-inset-bottom class="pop">
      <div class="pop-title">
        领取记录
        <van-icon name="cross" class="pop-close" color="#fff" @click="showCollectionRecords = false" />
      </div>
      <div class="collection-records">
        <div class="mode2" v-if="getObj.needAddress">
          <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/map.png" alt="" class="maplogo" />
          <div class="pub_onefull" v-if="getObj.addressVO">
            <div class="line1">{{ getObj.addressVO.province + getObj.addressVO.city + getObj.addressVO.district }}</div>
            <div class="line2">{{ getObj.addressVO.addressDetail }}</div>
            <div class="line3">
              {{ getObj.addressVO.name }} <span class="phone">{{ getObj.addressVO.phone }}</span>
            </div>
          </div>
        </div>
        <div class="mode1" v-else>已发放至您的优惠券账户，<br />可到线下超鹿门店找教练核销哦</div>
        <div class="jg"></div>
        <div class="records-box">
          <div class="records-model row-start-center">
            <div class="records-img">
              <img :src="getObj.rewardImg" alt="" />
            </div>
            <div class="records-info">
              <div class="records-name">{{ getObj.rewardName }}</div>
              <div class="records-tips" v-if="getObj.needAddress">活动结束后，45个工作日内发货，确认发货后将自动核销账户的实物优惠券。 </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>


    <InviteList ref="refInviteList" />

  </div>
</template>
<script>
import { newAppBack, initBack, saveCreateImg, webAppShare, defaultApp } from '@/lib/appMethod'

import userMixin from '@/mixin/userMixin'
import wx from 'weixin-js-sdk'

import InviteList from './inviteList.vue'

const inviteRewardsType = 'NEW_USER_USER_EXIT_REWARD_FOR_INVITERS'
const inviteType = 'NEW_USER_INVITE'
const extendedTime = 7
export default {
  components: {

    InviteList,
  },
  mixins: [userMixin],
  data() {
    return {
      showCollectionRecords: false, //显示领取记录弹窗
      userHeadImg: '',
      nickName: '',
      userPoster: '',
      rewardMap: {}, //奖品等级
      finished: false,
      showGet: false, //领取奖品弹窗
      inviteNum: 0,
      getObj: {}, //确认奖品展示
    }
  },
  methods: {
    saveCreateImg,
    newAppBack,
    onlyItem(item) {
      return item.some((d) => {
        return d.status == 'CAN_RECEIVE'
      })
    },
    sureReward() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios
        .post(this.baseURLApp + '/general/invitation/manualReceive', {
          inviteRewardsType,
          inviteType,
          rewardId: this.getObj.rewardId,
          userId: this.userId,
          token: this.token,
          extendedTime
        })
        .then((res) => {
          this.$toast.clear()
          this.showGet = false
          this.$toast('领取成功')
          this.getRewardList()
        })
        .catch(() => {
          this.close()
        })
    },
    close() {
      this.$toast.clear()
      this.$dialog
        .alert({
          title: '活动已结束',
          message: '',
          overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' },
        })
        .then(() => {
          if (this.appTypeStr == 'mini') {
            wx.miniProgram.switchTab({ url: '/pages/home/index' })
          } else {
            this.newAppBack()
          }
        })
    },
    getRewrd(item) {
      if (item.status == 'CAN_RECEIVE') {
        if (item.needAddress) {
          // this.$toast('暂不支持配送');
          const path = '/superdeer-activity/receive-reward/index?rewardId=' + item.rewardId + '&inviteRewardsType=' + inviteRewardsType + '&inviteType=' + inviteType
          this.$router.push(path)
        } else {
          this.getObj = item
          this.showGet = true
        }
      } else if (item.status == 'RECEIVED') {
        this.$axios
          .post(this.baseURLApp + '/general/invitation/getRewardDetail', {
            inviteRewardsType,
            inviteType,
            rewardId: item.rewardId,
            userId: this.userId,
            token: this.token,
            extendedTime
          })
          .then((res) => {
            this.getObj = res.data
            this.showCollectionRecords = true
          })
      }
    },
    getName(v) {
      const json = {
        RECEIVED: '已领取',
        RECEIVED_OTHER: '已领取其他奖品',
        CAN_RECEIVE: '领取',
        TASK_FULFILLED: '任务未达成',
      }
      return json[v]
    },
    torewardlist() {
      this.$refs.refInviteList.open()
    },

    getRewardList() {
      this.$axios
        .post(this.baseURLApp + '/general/invitation/manualReceiveDetail', {
          inviteRewardsType,
          inviteType,
          userId: this.userId,
          token: this.token,
          extendedTime
        })
        .then((res) => {
          const d = res.data
          this.inviteNum = d.inviteNum
          this.rewardMap = d.rewardMap

        })
        .catch(() => {
          this.close()
        })
    },
  },
  mounted() {
    // this.bindHeaderScroll(this.$el, 200, (scrollTop, morehalf) => {})
    // document.addEventListener('visibilitychange', () => {
    //   if (document.visibilityState === 'visible') {
    //     this.getRewardList()
    //   }
    // })
  },
  async created() {
    initBack()
    // await this.$getAllInfo(['userId', 'cityId'])
    this.getRewardList()
  },
}
</script>
<style lang="less" scoped>
.page-store-invite-202312 {
  background: #FFE8AB;
  padding: 40px 0 144px;
  margin: -1px 0 0;
  div {
    box-sizing: border-box;
  }

  img {
    vertical-align: top;
    width: 100%;
  }


  .gift-head {
    height: 140px;
    background: url(https://img.chaolu.com.cn/ACT/training-record/invite/suchead.png);
    background-size: cover;
    position: relative;
    margin: 0 24px 0;

    .to-reward-list {
      display: flex;
      position: absolute;
      right: 30px;
      top: 22px;
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      align-items: center;

      .arrow-icon {
        width: 32px;
        height: 32px;
        background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/arrow.png);
        background-size: cover;
        margin-left: 16px;
      }
    }
  }

  .gift-list {
    background: #FFF4DB;
    margin: 0 24px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 0 0 28px;

    .level-model {
      padding-bottom: 48px;

      .level-head {
        display: flex;
        align-items: center;

        .level-num {
          background: url(https://img.chaolu.com.cn/ACT/training-record/invite/coner.png);
          background-size: 100% 100%;
          width: 140px;
          height: 64px;
          line-height: 72px;
          text-align: center;
          color: #fff;
          font-size: 28px;
          position: relative;
          top: -4px;
          left: -8px;
          margin-right: 14px;
          font-weight: bold;
        }

        .finished {
          font-size: 26px;
          color: #fd3665;
          font-weight: bold;
          position: relative;
          padding-left: 40px;
          line-height: 1;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -15px;
            width: 30px;
            height: 30px;
            background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/finished.png);
            background-size: cover;
          }
        }

        .nofinished {
          font-weight: bold;
          font-size: 26px;
          color: #242831;
        }
      }

      .level-body {
        display: flex;
        flex-wrap: wrap;
        padding: 0 28px;
        margin-top: 12px;
        .reward-model {
          width: 204px;
          height: 344px;
          border-radius: 10px;
          background: #fff;
          text-align: center;
          margin-top: 16px;

          &:nth-child(3n + 2) {
            margin-left: 16px;
            margin-right: 16px;
          }

          .level-img {
            width: 140px;
            height: 140px;
            margin: 32px auto 16px;

            img {
              height: 100%;
              width: 100%;
            }
          }

          .level-name-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 172px;
            height: 72px;
            margin: 0 auto;

            .level-name {
              font-size: 24px;
              color: #432f17;
              font-weight: bold;
            }
          }

          .level-sub {
            border-radius: 8px;
            background: #FD3665;
            text-align: center;
            height: 52px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 172px;
            margin: 16px auto 0;
            font-size: 24px;
            color: #fff;
            font-weight: bold;

            .getimg {
              width: 28px;
              height: 28px;
              margin-left: 16px;
            }
          }

          .op {
            opacity: 0.5;
          }

          .hasget {
            font-size: 22px;
          }
        }
      }
    }
  }

  .rule-box {
    margin: 60px 24px 0;
    padding-bottom: 100px;
  }

  .tip-box {
    position: relative;

    .tip-img {
      position: absolute;
      top: 7px;
      right: 130px;
      width: 300px;
    }
  }

  .pop-get {
    border-radius: 16px;
    width: 560px;
    height: 790px;
    background: linear-gradient(180deg, #e6faff 0%, #fbe6ca 100%);
    text-align: center;

    .pop-get-title {
      color: #822d13;
      font-weight: bold;
      font-size: 44px;
      margin-top: 64px;
      margin-bottom: 24px;
    }

    .pop-get-tips {
      color: #822d13;
      font-size: 24px;
      line-height: 36px;
    }

    .jp {
      width: 240px;
      margin: 70px auto;
    }

    .pop-get-sub {
      width: 360px;
      line-height: 96px;
      color: #fff;
      background: linear-gradient(to right, #ff7f37 0%, #ff3672 100%);
      margin: 0 auto 30px;
      border-radius: 10px;
      font-weight: bold;
    }

    .noget {
      font-size: 28px;
      color: #822d13;
    }
  }

  .pop {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .pop-title {
      color: #432f17;
      font-weight: bold;
      background: linear-gradient(180deg, #fcf2da 0%, #ffffff 100%);
      font-size: 40px;
      text-align: center;
      line-height: 120px;
      position: relative;

      .pop-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
        font-size: 28px;
      }
    }
  }

  .collection-records {
    .mode1 {
      font-size: 26px;
      color: #5a0a00;
      text-align: center;
      padding: 32px 0;
    }

    .mode2 {
      display: flex;
      align-items: center;
      padding: 40px 32px 56px;
      position: relative;

      &::after {
        content: '';
        height: 4px;
        background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/border.png);
        background-size: cover;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .maplogo {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }

      .line1 {
        font-size: 24px;
        color: #666;
      }

      .line2 {
        color: #242831;
        font-size: 30px;
        font-weight: bold;
        margin: 20px 0;
      }

      .line3 {
        color: #242831;
        font-size: 26px;

        .phone {
          color: #666;
        }
      }
    }

    .jg {
      height: 16px;
      background: #f5f5f5;
    }

    .records-box {
      min-height: 520px;
    }

    .records-model {
      padding: 48px 32px 0;

      .records-img {
        width: 160px;
        height: 160px;
        margin-right: 40px;
      }

      .records-name {
        font-size: 28px;
        font-weight: bold;
        color: #242831;
        margin-bottom: 20px;
      }

      .records-tips {
        font-size: 22px;
        color: #666;
        line-height: 34px;
      }
    }
  }

}
</style>
